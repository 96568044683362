export default {
  name: "elDialogSec",
  emits: ["onsubmit", "closed"],
  props: {
    width: {
      type: String,
      default: "480px"
    },
    // 宽度
    defaultBtn: {
      type: Boolean,
      default: true
    },
    // 是否显示默认按钮
    appToBody: {
      type: Boolean,
      default: false
    } // 嵌入dialog
  },
  data() {
    return {
      visible: false,
      // 控制dialog显隐
      title: "" // dialog标题
    };
  },
  methods: {},
  mounted() {}
};