import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "leftChildMenu"
};
const _hoisted_2 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_tree = _resolveComponent("el-tree");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
    "suffix-icon": 'Search',
    ref: "treeInput",
    placeholder: "输入关键字搜索",
    modelValue: $data.filterText,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.filterText = $event),
    clearable: ""
  }, null, 8, ["modelValue"]), _createVNode(_component_el_scrollbar, {
    class: "elscrModules"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tree, {
      data: $props.data,
      class: "flowSchemesTree",
      "node-key": "id",
      style: {
        "width": "100%",
        "min-width": "200px"
      },
      "filter-node-method": $options.filterNode,
      props: $props.props,
      "default-expand-all": $props.expandALL,
      "default-expanded-keys": $props.expandedKeys,
      onNodeClick: $options.nodeClick,
      "show-scrollbar": "",
      ref: "tree"
    }, {
      default: _withCtx(({
        node,
        data
      }) => [_createElementVNode("span", {
        title: node.label
      }, _toDisplayString(node.label), 9, _hoisted_2)]),
      _: 1
    }, 8, ["data", "filter-node-method", "props", "default-expand-all", "default-expanded-keys", "onNodeClick"])]),
    _: 1
  })]);
}