import SignaturePad from 'signature_pad';
export default {
  name: 'signature',
  components: {
    SignaturePad
  },
  emits: ["saveSignature"],
  data() {
    return {
      signaturePad: null
    };
  },
  mounted() {
    this.signaturePad = new SignaturePad(this.$refs.signatureCanvas);
  },
  methods: {
    saveSignature() {
      if (this.signaturePad._isEmpty) {
        this.$emit("saveSignature", "");
      } else {
        const imageData = this.signaturePad.toDataURL();
        this.$emit("saveSignature", imageData);
      }
    },
    clearSignature() {
      this.signaturePad.clear();
    }
  }
};