//todo 遍历全文数据项 根据传入的数据赋值数据项和当前日期
import { Base64 } from "js-base64";
export default {
  emits: ["save"],
  data() {
    return {
      writer: null,
      writerState: {
        printpreview: false
      },
      toolBtn: {
        size: 18,
        color: "#4A5467"
      },
      fontName: "宋体",
      fontSize: "12",
      fontColor: "#000000",
      backColor: "#ffffff",
      fontList: [{
        label: "宋体",
        value: "宋体"
      }, {
        label: "楷体",
        value: "楷体"
      }, {
        label: "黑体",
        value: "黑体"
      }, {
        label: "微软雅黑",
        value: "微软雅黑"
      }, {
        label: "微软正黑体",
        value: "微软正黑体"
      }, {
        label: "新宋体",
        value: "新宋体"
      }, {
        label: "仿宋",
        value: "仿宋"
      }, {
        label: "幼圆",
        value: "幼圆"
      }, {
        label: "隶书",
        value: "隶书"
      }, {
        label: "华文细黑",
        value: "华文细黑"
      }, {
        label: "华文楷体",
        value: "华文楷体"
      }, {
        label: "华文宋体",
        value: "华文宋体"
      }, {
        label: "华文中宋",
        value: "华文中宋"
      }, {
        label: "华文彩云",
        value: "华文彩云"
      }, {
        label: "华文琥珀",
        value: "华文琥珀"
      }, {
        label: "方正舒体",
        value: "方正舒体"
      }, {
        label: "方正姚体",
        value: "方正姚体"
      }],
      fontSizeList: [{
        value: "8",
        value: "8"
      }, {
        value: "9",
        value: "9"
      }, {
        value: "10",
        value: "10"
      }, {
        value: "11",
        value: "11"
      }, {
        value: "12",
        value: "12"
      }, {
        value: "14",
        value: "14"
      }, {
        value: "16",
        value: "16"
      }, {
        value: "18",
        value: "18"
      }, {
        value: "20",
        value: "20"
      }, {
        value: "22",
        value: "22"
      }, {
        value: "24",
        value: "24"
      }, {
        value: "26",
        value: "26"
      }, {
        value: "28",
        value: "28"
      }, {
        value: "36",
        value: "36"
      }, {
        value: "48",
        value: "48"
      }, {
        value: "72",
        value: "72"
      }],
      customFieldSelected: null,
      //当前光标选中的自定义关键词输入域对象
      customFieldChecked: null,
      //单选时选中的关键词选项，字符串
      customFieldMoreChecked: [],
      //多选时选中的关键词选项，数组
      customFieldCommonItem: null,
      //关键词数据库中存储的对象
      customFieldList: [],
      dataFieldList: [],
      needDialog: ["InsertImage", "InsertHorizontalLine", "ParagraphFormat", "Table_SplitCellExt", "Font", "FileOpen", "InsertCheckBoxOrRadio"],
      dateFieldSelected: null,
      dateValue: '',
      dateFormat: 'YYYY-MM-DD',
      xml: ""
    };
  },
  methods: {
    //将内容转换为可以直接使用的xml，并加载
    loadWS(mbtext) {
      let xml = "";
      if (mbtext) {
        xml = Base64.decode(mbtext);
      }
      if (xml === "") {
        this.writer.DCExecuteCommand("ClearBodyContent", false, null);
      } else {
        this.writer.LoadDocumentFromString(xml);
      }
    },
    // 获取控件中的文书内容
    getWS() {
      return this.writer.SaveDocumentToBase64String('xml');
    },
    //设置fieldObj的值
    setDataField(element, fieldObj) {
      if (this.baseInfo && fieldObj.Attributes.DataModelMap && this.baseInfo[fieldObj.Attributes.DataModelMap] && fieldObj.Attributes.DataColMap) {
        let value = this.baseInfo[fieldObj.Attributes.DataModelMap][fieldObj.Attributes.DataColMap];
        if (value) {
          if (typeof value === 'string' && value.startsWith("data:image/png;base64,")) {
            // 删除原有图片
            let tempelements = element.GetElementProperties(fieldObj.ID).Elements;
            tempelements.forEach(tempelement => {
              if (element.GetElementById(tempelement).TypeName == "XTextImageElement") {
                element.DeleteElementByID(tempelement);
              }
            });
            let img = [{
              'image': {
                src: value,
                width: 400,
                height: 200,
                savecontentinfile: true
              }
            }];
            element.SetChildElements(fieldObj.ID, img, "afterBegin");
          } else {
            value = String(value);
            element.SetElementTextByID(fieldObj.ID, value);
          }
        }
      }
    },
    // 重置所有内容
    setDocumentAllFields(baseinfo) {
      this.baseInfo = baseinfo;
      let newCtl = document.getElementById("myWriterControl");
      let allFields = newCtl.GetAllInputFields();
      console.log(allFields);
      for (let i = 0; i < allFields.length; i++) {
        const item = allFields[i];
        switch (item.Attributes.DataType) {
          case "数据项":
            this.setDataField(newCtl, item);
            break;
          case "日期数据":
            if (item.Attributes.DataValue === '当天日期') {
              newCtl.SetElementTextByID(item.ID, this.$moment(new Date()).format('YYYY年M月D日'));
            }
            break;
          default:
            break;
        }
      }
    },
    // 初始化文书控件
    initWriter() {
      let newCtl = document.getElementById("myWriterControl");
      window.CreateWriterControlForWASM(newCtl);
      this.writer = newCtl;
      window.WriterControl_EventElementMouseClick = (e, a) => {
        let font = this.writer.getFontObject();
        this.fontName = font.FontFamily;
        this.fontSize = font.FontSize;
      };
      newCtl.ondocumentdblclick = e => {
        let result = this.writer.CurrentInputField();
        if (result) {
          let currentinputfield = this.writer.GetElementProperties(result);
          let attr = currentinputfield.Attributes;
          if (attr) {
            switch (attr.DataType) {
              case '自定义项':
                this.customFieldSelected = currentinputfield;
                this.loadCustomData();
                break;
              case '日期数据':
                if (attr.DataValue != '当天日期') {
                  this.dateFieldSelected = currentinputfield;
                  this.loadDateData();
                }
                break;
              default:
                break;
            }
          }
        }
      };
    },
    commandHandler(command) {
      switch (command) {
        case "Save":
          let mbtext = this.writer.SaveDocumentToBase64String('xml');
          this.$emit("save", mbtext);
          break;
        case "pageSet":
          this.writer.DocumentSettingsDialog(); //
          break;
        case "printpreview":
          this.writer.LoadPrintPreview();
          this.writerState.printpreview = true;
          break;
        case "closeprintpreview":
          this.writer.ClosePrintPreview();
          this.writerState.printpreview = false;
          break;
        case "print":
          this.writer.PrintDocument();
          break;
        case "insertTable":
          this.writer.insertTableDialog();
          break;
        case "AlignType":
          this.$refs.dialog.visible = true;
          break;
        case "Font":
          let fontObj = this.writer.getFontObject();
          this.writer.setFontObject(fontObj);
          this.writer.DCExecuteCommand(command, true, null);
          break;
        case "Color":
          this.$refs.fontColor.show();
          break;
        case "BackColor":
          this.$refs.backColor.show();
          break;
        default:
          if (this.needDialog.indexOf(command) > -1) {
            this.writer.DCExecuteCommand(command, true, null);
          } else {
            this.writer.DCExecuteCommand(command, false, null);
          }
          break;
      }
    },
    loadCustomData() {
      this.$axios.get("/api/Business/GetCommonitemByBH?bh=" + this.customFieldSelected.Attributes.DataValue, {}).then(res => {
        if (res.status == 200) {
          this.customFieldCommonItem = res.data.result;
          this.customFieldList.length = 0;
          this.customFieldList.push(...this.customFieldCommonItem.itemText.split('|')); //vue3中不能直接给数组赋值，清空时需要让length=0，赋值用解构方式，或者遍历选项push进去

          //获取当前输入域的text，默认选中
          if (this.customFieldCommonItem.isMore && this.customFieldSelected.Text != this.customFieldCommonItem.itemName) {
            //多选时
            if (this.customFieldSelected.Text) {
              let textArr = this.customFieldSelected.Text.split('、').filter(item => item != ''); //这里先不用库里的分隔方式，全都默认按顿号
              this.customFieldMoreChecked.length = 0;
              this.customFieldMoreChecked.push(...textArr); //vue3直接赋值 会丢失响应属性
            } else {
              this.customFieldMoreChecked.length = 0;
            }
          } else {
            if (this.customFieldList.indexOf(this.customFieldSelected.Text) > -1) {
              this.customFieldChecked = this.customFieldSelected.Text;
            }
          }
          this.$refs.customDataDialog.visible = true;
        }
      }).catch();
    },
    loadDateData() {
      this.dateFormat = this.dateFieldSelected.Attributes.DataValue;
      let text = this.dateFieldSelected.Text;
      if (this.dateFieldSelected.Text) {
        //如果带年月日文字 先替换一下
        text = text.replace(/[年月]/g, "-").replace(/[日]/, "");
        console.log(text);
        if (this.$moment(text).isValid()) {
          this.dateValue = this.$moment(text).format('YYYY-MM-DD');
        }
      }
      this.$refs.dateFieldDialog.visible = true;
    },
    setCustomData() {
      let fieldID = this.customFieldSelected.ID;
      if (this.customFieldCommonItem.isMore) {
        //多选时
        if (this.customFieldMoreChecked.length > 0) {
          this.writer.SetElementTextByID(fieldID, this.customFieldMoreChecked.join('、')); //这里暂时用顿号，正常需要用户配置关键词时配置好
          this.$refs.customDataDialog.visible = false;
        } else {
          this.$ElMessage.warning('请至少选择一个选项');
        }
      } else {
        if (this.customFieldChecked) {
          this.writer.SetElementTextByID(fieldID, this.customFieldChecked);
          this.$refs.customDataDialog.visible = false;
        } else {
          this.$ElMessage.warning('请选择一个选项');
        }
      }
    },
    setDateData() {
      let fieldID = this.dateFieldSelected.ID;
      if (this.dateValue) {
        console.log(this.dateValue);
        this.writer.SetElementTextByID(fieldID, this.$moment(this.dateValue).format(this.dateFormat));
        this.$refs.dateFieldDialog.visible = false;
      }
    },
    clearCustomFieldVar() {
      this.customFieldSelected = null;
      this.customFieldChecked = "";
      this.customFieldCommonItem = null;
      this.customFieldList.length = 0;
    },
    clearDateFieldVar() {
      this.dateFormat = "YYYY-MM-DD";
      this.dateFieldSelected = null;
      this.dateValue = "";
    },
    fontNameChange() {
      this.writer.DCExecuteCommand("FontName", false, this.fontName);
    },
    fontSizeChange() {
      this.writer.DCExecuteCommand("FontSize", false, this.fontSize);
    },
    fontColorChange(color) {
      this.writer.DCExecuteCommand("Color", false, color);
    },
    backColorChange(color) {
      this.writer.DCExecuteCommand("BackColor", false, color);
    },
    ClearBodyContent() {
      this.writer.DCExecuteCommand("ClearBodyContent", false, null);
    }
  },
  mounted() {
    this.initWriter();
  },
  created() {},
  computed: {}
};