export default {
  name: 'treeFilter',
  emits: ['nodeClick'],
  props: {
    expandALL: {
      type: Boolean,
      default: true
    },
    expandedKeys: {
      type: Array,
      default: []
    },
    data: {
      type: Array,
      default: []
    },
    props: {
      type: Object,
      default: {
        children: 'children',
        label: 'name'
      }
    }
  },
  data() {
    return {
      filterText: ""
    };
  },
  methods: {
    // tree绑定搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    nodeClick(data, node, treenode, event) {
      this.$emit('nodeClick', data, node, treenode, event);
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  }
};